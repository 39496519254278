import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, Ref, } from 'vue-property-decorator';
import { Form, FormItem } from '@/components/shared/CForm/index';
import { UnitType } from '@/typings/organization/org-manage';
import { PermManageService } from '@/sevices';
import { OperateType } from '@/typings/global';
import { StaffSelector } from '@cloudpivot-hermes/common-components';
import { NodeType } from '../../../typings/organization/org-manage';
import { SysAdmin } from '../../../typings/perm-manage';
let AddManager = class AddManager extends Vue {
    constructor() {
        super(...arguments);
        this.visible = false;
        this.form = {
            userList: [],
            managerRange: [],
        };
        this.OperateType = {
            Add: OperateType.Add,
            Edit: OperateType.Edit,
        };
        this.modalTitle = '添加管理员';
        this.submiting = false;
        this.rules = {
            userList: [
                { required: true, message: '选择人员不能为空', trigger: 'change' },
            ],
            managerRange: [
                { required: true, message: '管理范围不能为空', trigger: 'change' },
            ],
        };
    }
    get isSysAdmin() {
        return this.roleId === SysAdmin.SYS;
    }
    async comfirm() {
        const isValid = this.cform.validate();
        if (!isValid)
            return;
        if (this.operateType === OperateType.Edit && !this.managerDetail.userId) {
            this.$message.error(`${this.managerDetail.userName} 用户数据存在异常，无法保存！`);
            return;
        }
        try {
            this.submiting = true;
            this.operateType === OperateType.Add
                ? await this.addManager()
                : await this.updateManager();
        }
        finally {
            this.submiting = false;
        }
    }
    getUserList(userList = []) {
        const list = [];
        for (const item of userList) {
            list.push({
                baseUserId: item.source.baseUserId,
                name: item.source.name,
                userId: item.source.id,
            });
        }
        return list;
    }
    getRange(rangeList = []) {
        const list = [];
        for (const item of rangeList) {
            list.push({
                unitId: item.source.id,
                unitName: item.source.name,
                orgType: item.selectedNode.nodeType,
                type: item.type,
            });
        }
        return list;
    }
    // 添加管理员
    async addManager() {
        const params = {
            roleId: this.roleId,
            userList: this.getUserList(this.form.userList),
            managerRange: this.getRange(this.form.managerRange),
        };
        const addAuthGroupRes = await PermManageService.addManager(params);
        if (!addAuthGroupRes.success)
            return;
        this.$message.success('添加管理员成功');
        this.$emit('reload');
        this.cancel();
    }
    // 编辑管理员
    async updateManager() {
        const params = {
            id: this.managerDetail.id,
            roleId: this.roleId,
            userList: this.getUserList(this.form.userList),
            managerRange: this.getRange(this.form.managerRange),
        };
        const updateRoleRes = await PermManageService.updateManager(params);
        if (!updateRoleRes.success)
            return;
        this.$message.success('编辑管理员范围成功');
        this.$emit('reload');
        this.cancel();
    }
    cancel() {
        this.$emit('input', false);
    }
    async onValueChange(v) {
        this.visible = v;
        if (!v) {
            return;
        }
        if (this.operateType === OperateType.Edit) {
            this.modalTitle = '修改管理范围';
            if (this.managerDetail.id) {
                this.form.userList = [
                    {
                        id: this.managerDetail.userId,
                        name: this.managerDetail.userName,
                        type: UnitType.User,
                    },
                ];
                this.form.managerRange =
                    Array.isArray(this.managerDetail.manageRange) &&
                        this.managerDetail.manageRange.map((item) => {
                            return {
                                id: item.id,
                                name: item.name,
                                type: item.nodeType === NodeType.Org || item.nodeType === NodeType.Dept ? UnitType.Dept : UnitType.User,
                            };
                        });
            }
        }
        else {
            this.form = {
                userList: [],
                managerRange: [],
            };
            this.modalTitle = '新建管理员';
        }
    }
};
__decorate([
    Prop()
], AddManager.prototype, "value", void 0);
__decorate([
    Prop()
], AddManager.prototype, "roleId", void 0);
__decorate([
    Prop()
], AddManager.prototype, "managerDetail", void 0);
__decorate([
    Prop({ default: OperateType.Add })
], AddManager.prototype, "operateType", void 0);
__decorate([
    Ref()
], AddManager.prototype, "cform", void 0);
__decorate([
    Watch('value')
], AddManager.prototype, "onValueChange", null);
AddManager = __decorate([
    Component({
        name: 'AddManager',
        components: {
            CForm: Form,
            CFormItem: FormItem,
            StaffSelector,
        },
    })
], AddManager);
export default AddManager;
