export var OperateType;
(function (OperateType) {
    OperateType[OperateType["Add"] = 0] = "Add";
    OperateType[OperateType["Edit"] = 1] = "Edit";
    OperateType[OperateType["Del"] = 2] = "Del";
    OperateType[OperateType["EnableOrDisabled"] = 3] = "EnableOrDisabled";
    OperateType[OperateType["Move"] = 4] = "Move";
    OperateType[OperateType["Search"] = 5] = "Search";
    OperateType[OperateType["Null"] = 6] = "Null";
})(OperateType || (OperateType = {}));
